<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <title-text />
      </b-col>
      <b-col
        cols="12"
      >
        <content-text />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import TitleText from '@/views/Admin/Esg/elements/TitleText.vue'
import ContentText from '@/views/Admin/Esg/elements/ContentText.vue'
import { BCard, BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'ItemForm',
  components: {
    BCol, BRow, BCard, ContentText, TitleText,
  },
}
</script>
